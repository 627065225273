//.story-list-index-block {
//  padding: 3px 5px 5px 3px
//}

.story-list-index-boder {
  //width: 144px;
  //height: 211px;
  //border: 1px solid gray;
  border-radius: 4px;
  overflow: hidden;
}

//.story-list-index {
//  position: absolute;
//  top:      0px;
//  left:     0px;
//  bottom:   0px;
//  right:    0px;
//}

.story-image-block {
  width: 215px;
  max-height: 322px;
  text-align: center;
  margin-bottom: 20px;
}

//.story-list-index img {
//  margin: -18px 0 0 -12px;
//}

//.story-list-index-container {
//  position: relative;
//}
//.story-list-index-container:before {
//  content:     "";
//  display:     block;
//  padding-top: 150%;
//}


.story-new-list {
  border-bottom: 1px dotted gray;
  border-left: 1px dotted gray;
  border-right: 1px dotted gray;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  //background: white;
}

.story-new-list:first-child {
  //border-top: 1px dotted gray;
}

.story-new-list div:not(:last-child) {
  //border-right: 1px dashed gray;
}

.story-body {
  padding-left: 10px;
}

.story-image-img {
  max-height: 322px;
  //min-width: 100%;
}

.story-info-count {
  display: inline-block;
  padding-right: 20px;
}

.story-info-count div {
  display: inline-flex;
}

.story-info-count i {
  display: inline-flex;
  padding-right: 4px;
}

.story-statusOrigin-input {
  display: inline-block;
  width: 160px;
}

.story-chap-title {
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.story-list-empty {
  padding: 20px 10px 10px 10px;
}

.story-pagination-block .pagination {
  display: block;
  padding-top: 20px;
}

.story-pagination-block .page-item {
  display: inline-block;
}

.story-description-title {
  display: inline-block;
}

.story-desc-title-block {

}

.story-image-block {

}

.rm-text-container p {
  display: contents;
}

.story-image-e {
  max-width: 200px;
  max-height: 100px;
}

.story-state-icon-area {
  padding-left: 6px;
  display: inline-block;
}

.story-new-img-block {
  display: inline-block;
  width: 22px;
  text-align: center;
  padding-right: 5px;
  position: absolute;
  left: 0px;
  top: -3px;
}
.story-new-img {
  width: 100%;
  border-radius:2px;
}

.story-new-child-name {
  display: block;
  padding-left: 28px;
  position: relative;
}

.last-chap-block {
  //padding-left: 5px;
}

.last-chap-title {
  font-size: 18px;
}

.last-chap-list {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 10px;
}

.list-chap-chap {
  display: inline-block;
  vertical-align: middle;
}

.list-chap-update {
  display:inline-block;
  vertical-align: middle;
}

.chap-content {
  font-size: 21px;
}

.story-title {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
}

.story-title-chap {
  text-align: left;
  font-size: 34px;
  font-weight: bold;
}
