
//body {
//  background: url(https://doctruyenmoi.com/img/common/2020-tet.png) 50% 0px no-repeat fixed;
//}

.main {
  // background-color: #e4e5e6;
}

.chap-body .chap-content {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scrollbar-container {
  padding-bottom: 17px !important;
  padding-right: 8px !important; /* Increase/decrease this value for cross-browser compatibility */
  padding-left: 8px !important; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box !important; /* So the width will be 100% + 17px */
}

.adArea {
  max-width: 980px;
  height: 48px;
  position: relative;
  margin-right: 190px;
  display: block;
}

.adContainer {
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 20s;
  width: 200px;
}

.adImage {
  display: inline-block;
}

@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;    
  }
}

