.chap-move-button {

}

.chap-move-button.disable {
  background: gray;
  border-color: darkgrey;
}


.chap-near-list .dropdown-item{
  padding-left: 38px !important;
}

.chap-move-button-area {
  padding-top: 15px;
  padding-bottom: 15px;
  .button-left {
    padding-left: 6px;
  }
}

.chap-content-empty {
  text-align: center;
  font-size: 20px;
}
