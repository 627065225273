.home-block-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  // background-color: #e4dece;
}

.home-block-title-a {
  color: black;
  font-family: cursive;
  font-style: italic;
  font-size: 24px;
  padding-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.home-block-title-category {
  display: inline-block;
  vertical-align: middle;
  max-width: 50%;
}

.home-category-btn {
  background-color: white !important;
  color: gray !important;
}


.home-fb-block {
  padding-top: 30px;
}

.home-story-list-new-block {
  padding-bottom: 5px;
}

.home-story-count {
  font-size: 12px;
  color: white;
  text-align: right;
}

.home-story-history-empty {
  text-align: center;
}

.home-child-block {
  padding-left: 5px;
  padding-right: 5px;
  // background-color: #e4dece;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.index-search-block {
  text-align: center;
  display: block;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
}
.index-tag-hot-block {
  text-align: left;
  display: block;
  padding-top: 4px;
  padding-left: 5px;
  padding-right: 5px;
}

.hot-tag {
  border: 1px solid #007bff;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  display:inline-block;
  & span {
    color: rgb(163, 46, 148);
  }
}